import React, { useEffect } from 'react';

function Quiz() {
  useEffect(() => {
    // Redirect to the external URL
    window.location.href = 'https://hfws3gz2.forms.app/reduce-carbon-footprint-quiz';
  }, []);

  return (
    <div>
      <h1>Redirecting to Quiz...</h1>
    </div>
  );
}

export default Quiz;
