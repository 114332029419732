import React from 'react';

function CustomerStories() {
  return (
    <div>
      <h1>Customer Stories Page</h1>
    </div>
  );
}

export default CustomerStories;
