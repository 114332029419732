import React from 'react';
import './Home.css'; // Ensure you have a CSS file for styling
import sustainabilityCostCurve from '../sustainability_cost_curve.png'; // Adjust the path accordingly


const Home = () => {
  const imageStyle = {
    width: '100%',
    height: 'auto'
  };
  return (
    <div className="home">
      <header className="home-header">
        <h1>Welcome to Carbon Consultancy</h1>
        <p>Your partner in optimizing carbon footprint and enhancing business efficiency.</p>
      </header>
      <section className="home-content">
	      <p>At Carbon Consultancy, we are dedicated to helping businesses achieve sustainability by optimizing their carbon footprint. Our comprehensive suite of services is designed to identify inefficiencies, implement eco-friendly practices, and track progress toward a greener future. With our expertise, businesses can reduce their environmental impact, comply with regulatory requirements, and enhance their corporate social responsibility.</p>

    <p>We employ a data-driven approach to analyze and understand the unique carbon challenges of each business. By leveraging advanced analytics and industry best practices, we provide tailored solutions that maximize energy efficiency, minimize waste, and promote the use of renewable resources. Our team of experts works closely with clients to develop and implement strategic action plans, ensuring sustainable growth and a positive impact on the planet.</p>

    <p>At Carbon Consultancy, we believe that sustainability and profitability go hand in hand. By optimizing your carbon footprint, you can reduce operational costs, improve brand reputation, and attract environmentally conscious customers. Let us partner with you on your journey to sustainability, helping you create a healthier planet for future generations while driving business success.</p>
	  <h2>Sustainability vs. Cost of Business</h2>
<img src={sustainabilityCostCurve} alt="Bell curve showing sustainability vs. cost of business" style={imageStyle} />


        <h2>The Benefits of Optimizing Carbon Footprint for Businesses</h2>
        <p>
          In today's rapidly evolving business landscape, optimizing your carbon footprint is more than just an environmental responsibility—it's a strategic advantage. Reducing your company's carbon emissions not only contributes to a healthier planet but also enhances operational efficiency, reduces costs, and improves your brand's reputation. By adopting sustainable practices, businesses can streamline processes, cut waste, and ultimately save money. Energy-efficient technologies, waste reduction strategies, and sustainable sourcing can lead to significant cost savings and increased profitability.
        </p>
        <p>
          Moreover, companies that prioritize carbon footprint optimization often experience a boost in brand value and customer loyalty. Consumers and clients are increasingly making decisions based on environmental impact, and businesses that demonstrate a commitment to sustainability are better positioned to attract and retain eco-conscious customers. This positive public perception can enhance your brand's reputation, differentiate you from competitors, and open new market opportunities.
        </p>
        <p>
          In addition to economic and reputational benefits, optimizing your carbon footprint can lead to regulatory advantages. Governments worldwide are implementing stricter environmental regulations and carbon reduction targets. By proactively managing your carbon emissions, you can ensure compliance with current and future regulations, avoiding potential fines and legal complications.
        </p>
        <p>
          Investing in carbon footprint optimization also drives innovation within your organization. It encourages a culture of continuous improvement and problem-solving as you explore new technologies and practices to reduce your environmental impact. This forward-thinking approach can lead to breakthroughs in product development, operational efficiencies, and overall business practices.
        </p>
        <p>
          In summary, optimizing your carbon footprint is a vital step toward creating a sustainable and profitable business. It not only supports environmental stewardship but also drives operational efficiencies, enhances brand reputation, and ensures regulatory compliance. Embracing sustainability is not just a trend—it's a smart business decision that will benefit your company in the long run.
        </p>
      </section>
    </div>
  );
};

export default Home;
