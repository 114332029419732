import React, { useEffect } from 'react';

function Signout() { 
  useEffect(() => {
    // Redirect to the Sign in URL
    window.location.href = '../signout.php';
  }, []);

  return (
    <div>
      <h1>Signing out...</h1>
    </div>
  );
}

export default Signout;

