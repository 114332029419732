import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';


function SignIn() {
    const [formData, setFormData] = useState({
        email: '',
        password: ''
    });
    const navigate = useNavigate();
    const { user, login } = useAuth();

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const response = await fetch('../signin.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(formData)
        });

        const result = await response.json();
	if (result.success) {
            login(result.user); // Set the user in the context
            navigate('../../platform');
        } else {
            alert(result.message);
        }
    };

    return (
        <div>
            <h2>Sign In</h2>
            <form onSubmit={handleSubmit}>
                <label>
                    Email:
                    <input type="email" name="email" id="email" value={formData.email} onChange={handleChange} required />
                </label><br /><br />

                <label>
                    Password:
                    <input type="password" name="password" id="password" value={formData.password} onChange={handleChange} required />
                </label><br /><br />

                <input type="submit" value="Sign In" />
            </form>
        </div>
    );
}

export default SignIn;
