import React from 'react';

function Inspection() {
  return (
    <div>
      <h1>Inspection Page</h1>
    </div>
  );
}

export default Inspection;
