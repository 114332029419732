import React, { useEffect } from 'react';

function ScheduleMeeting() {
  useEffect(() => {
    // Redirect to the external URL
    window.location.href = 'https://calendly.com/consultancycarbon';
  }, []);

  return (
    <div>
      <h1>Scheduling Meeting ...</h1>
    </div>
  );
}


export default ScheduleMeeting;
