import React from 'react';

function Assets() {
  return (
    <div>
      <h1>Assets Page</h1>
    </div>
  );
}

export default Assets;
