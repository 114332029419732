import React from 'react';
import { Link } from 'react-router-dom';
import './USP.css';
import logo from '../logo.jpg'; // Adjust the path as needed

function USP() {
  return (
    <div>
      <h1>USP Page</h1>
      <nav>
        <ul>
          <li className="logo-container">
            <img src={logo} alt="Carbon Consultancy" className="logo" />
          </li>
          <li><Link to="/">Home</Link></li>
          <li><Link to="/usp/checklists">Checklists</Link></li>
          <li><Link to="/usp/testimonials">Testimonials</Link></li>
          <li><Link to="/usp/customerstories">Customer Stories</Link></li>
        </ul>
      </nav>
      <section className="usp-content">
        <h2>Unique Selling Points</h2>
        <p><strong>Tailored Carbon Reduction Strategies:</strong> Our Carbon Consulting service provides customized solutions designed specifically for your business’s unique needs and industry requirements. We analyze your current carbon footprint, identify key areas for improvement, and develop a strategic plan that aligns with your sustainability goals and operational constraints.</p>

        <p><strong>Cutting-Edge Technology Integration:</strong> Leverage the latest advancements in technology with our consulting services. We utilize state-of-the-art tools and software to accurately measure and monitor your carbon emissions, implement efficient energy management systems, and integrate innovative solutions that drive substantial reductions in your carbon footprint.</p>

        <p><strong>Comprehensive Regulatory Compliance:</strong> Navigate the complex landscape of environmental regulations with ease. Our team ensures that your business remains compliant with local, national, and international carbon reduction standards. We provide up-to-date insights on regulatory changes and help you implement necessary measures to avoid penalties and enhance your corporate reputation.</p>

        <p><strong>Holistic Sustainability Approach:</strong> We offer a holistic approach to sustainability that goes beyond carbon reduction. Our consulting services address various aspects of environmental impact, including resource efficiency, waste management, and social responsibility. By integrating these elements into your strategy, we help you achieve a well-rounded and effective sustainability program.</p>

        <p><strong>Proven Track Record of Success:</strong> Benefit from our extensive experience and proven track record in delivering measurable results. Our team has successfully assisted numerous businesses in achieving significant carbon reductions, cost savings, and enhanced sustainability credentials. We provide case studies and testimonials that demonstrate our expertise and the tangible benefits of our services.</p>
      </section>
    </div>
  );
}

export default USP;
