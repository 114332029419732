import React from 'react';

function Sensors() {
  return (
    <div>
      <h1>Sensors Page</h1>
    </div>
  );
}

export default Sensors;
