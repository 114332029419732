import React from 'react';
import Navigation from './components/Navigation';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import Signup from './pages/Signup';
import Signin from './pages/Signin';
import Signout from './pages/Signout';
import Quiz from './pages/Quiz';
import ScheduleMeeting from './pages/ScheduleMeeting';
import Contact from './pages/Contact';
import Platform from './pages/Platform';
import Assets from './pages/Assets';
import Inspection from './pages/Inspection';
import Sensors from './pages/Sensors';
import Issues from './pages/Issues';
import Integrations from './pages/Integrations';
import Monitoring from './pages/Monitoring';
import AITools from './pages/AITools';
import USP from './pages/USP';
import Checklists from './pages/Checklists';
import Testimonials from './pages/Testimonials';
import CustomerStories from './pages/CustomerStories';
import NotFound from './pages/NotFound';
import Pricing from './pages/Pricing';
import Home from './pages/Home';
import { AuthProvider } from './context/AuthContext';


const App = () => (
  <AuthProvider>
  <Router>
      <div>
      <Navigation />
      <Routes>
        <Route exact path="/" element={<Home/>} />
        <Route exact path="/app/signup" element={<Signup/>} />
        <Route exact path="/app/signin" element={<Signin/>} />
        <Route exact path="/app/signout" element={<Signout/>} />
        <Route exact path="/app/quiz" element={<Quiz/>} />
        <Route exact path="/app/schedulemeeting" element={<ScheduleMeeting/>} />
        <Route exact path="/app/contact" element={<Contact/>} />
        <Route exact path="/app/pricing" element={<Pricing/>}/>
        <Route exact path="/platform" element={<Platform/>} />
        <Route exact path="/platform/assets" element={<Assets/>} />
        <Route exact path="/platform/inspection" element={<Inspection/>} />
        <Route exact path="/platform/sensors" element={<Sensors/>} />
        <Route exact path="/platform/issues" element={<Issues/>} />
        <Route exact path="/platform/integrations" element={<Integrations/>} />
        <Route exact path="/platform/monitoring" element={<Monitoring/>} />
        <Route exact path="/platform/aitools" element={<AITools/>} />
        <Route exact path="/usp" element={<USP/>} />
        <Route exact path="/usp/checklists" element={<Checklists/>} />
        <Route exact path="/usp/testimonials" element={<Testimonials/>} />
        <Route exact path="/usp/customerstories" element={<CustomerStories/>} />
        <Route path="*" element={<NotFound/>}/>
      </Routes>
    </div>
  </Router>
  </AuthProvider>
);

export default App;
