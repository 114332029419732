import React from 'react';

function Testimonials() {
  return (
    <div>
      <h1>Testimonials Page</h1>
    </div>
  );
}

export default Testimonials;
