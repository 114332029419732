import React from 'react';

function Monitoring() {
  return (
    <div>
      <h1>Monitoring Page</h1>
    </div>
  );
}

export default Monitoring;
