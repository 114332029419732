import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

function SignUp() {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        password: '',
        confirmPassword: ''
    });
    const navigate = useNavigate();
    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
	if(formData.email.includes("carbonconsultancy.net")) {
		alert('This domain is not allowed');
		return;
	}

        if (formData.password !== formData.confirmPassword) {
            alert('Passwords do not match');
            return;
        }

        const response = await fetch('../signup.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(formData)
        });

        const result = await response.text();
	if (result.includes("Sign up success")) {
            navigate('../app/signin');
        } else {
            alert(result);
        }
    };

    return (
        <div>
            <h2>Sign Up</h2>
            <form onSubmit={handleSubmit}>
                <label>
                    Name:
                    <input type="text" name="name" id="name" value={formData.name} onChange={handleChange} required />
                </label><br /><br />

                <label>
                    Email:
                    <input type="email" name="email" id="name" value={formData.email} onChange={handleChange} required />
                </label><br /><br />

                <label>
                    Password:
                    <input type="password" name="password" id="password" value={formData.password} onChange={handleChange} required />
                </label><br /><br />

                <label>
                    Confirm Password:
                    <input type="password" name="confirmPassword" id="confirmPassword" value={formData.confirmPassword} onChange={handleChange} required />
                </label><br /><br />

                <input type="submit" value="Sign Up" />
            </form>
        </div>
    );
}

export default SignUp;
