import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Pricing.css';

const Pricing = () => {
  const navigate = useNavigate();

  const handleTrialClick = () => {
    navigate('/app/schedulemeeting');
  };
  return (
    <section className="pricing-page-section" id="pricing">
      <div className="container my-auto">
        <div className="row">
          <div className="col-lg-12 mx-auto text-center">
            <h1> Optimize Your Carbon Footprint</h1>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            <div className="pricing-card">
              <h2>Basic</h2>
              <p>For individual or small business looking to optimize their carbon footprint.</p>
              <div className="price">
                <span className="currency">$</span>
                <span className="amount">25</span>
                <span className="duration">/ user / month<br /></span>
              </div>
              <p>Up to 10 users</p>
              <button className="btn btn-primary"  onClick={handleTrialClick}>Get started</button>
              <ul className="features">
                <li>Up to 10 team members</li>
                <li>Conduct unlimited digital inspections</li>
                <li>Assign and execute tasks</li>
                <li>Deliver workplace training</li>
                <li>Capture and report issues</li>
                <li>Manage workplace communications and assets</li>
              </ul>
            </div>
          </div>
          <div className="col-md-4">
            <div className="pricing-card popular">
              <h2>Most popular</h2>
              <h2>Premium</h2>
              <p>For teams who need deeper insights and collaboration to optimize carbon footprint.</p>
              <div className="price">
                <span className="currency">$</span>
                <span className="amount">50</span>
                <span className="duration">/ user / month<br /></span>
              </div>
              <button className="btn btn-primary"  onClick={handleTrialClick}>Start your 30-day free trial</button>
              <ul className="features">
                <li>All the benefits of ‘Free’, plus:</li>
                <li>Unlimited team members, including lite seats</li>
                <li>Gain insight with analytics</li>
                <li>Connect your tools with seamless integrations</li>
                <li>Provide secure access with SSO</li>
                <li>Compare performance across sites</li>
                <li>Share customized reports</li>
                <li>Safeguard data with advanced access rules</li>
                <li>Provide free guest access for external users</li>
              </ul>
            </div>
          </div>
          <div className="col-md-4">
            <div className="pricing-card">
              <h2>Enterprise</h2>
              <p>For large businesses who need advanced integrations and dedicated support.</p>
              <div className="price">
              <button className="btn btn-primary"  onClick={handleTrialClick}>Get a custom price</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Pricing;
