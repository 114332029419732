import React from 'react';
import { Link } from 'react-router-dom';
import './Navigation.css';
import logo from '../logo.jpg'; // Adjust the path as needed

const Navigation = () => (
  <nav>
    <ul>
      <li className="logo-container"><img src={logo} alt="Carbon Consultancy" className="logo" /></li>
      <li><Link to="/">Home</Link></li>
      <li><Link to="/app/signup">Signup</Link></li>
      <li><Link to="/app/signin">Signin</Link></li>
      <li><Link to="/app/signout">Signout</Link></li>
      <li><Link to="/app/quiz">Quiz</Link></li>
      <li><Link to="/app/schedulemeeting">Schedule Meeting</Link></li>
      <li><Link to="/app/contact">Contact</Link></li>
      <li><Link to="/app/pricing">Pricing</Link></li>
      <li><Link to="/platform">Platform</Link></li>
      <li><Link to="/usp">USP</Link></li>
    </ul>
  </nav>
);

export default Navigation;
