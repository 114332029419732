import React, { useState } from 'react';
import './Contact.css';

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    subject: '',
    message: '',
  });

  const [ipAddress, setIpAddress] = useState('');

  const fetchIpAddress = async (e) => {
    const response = await fetch('https://api64.ipify.org?format=json');
    const data = await response.json();
    setIpAddress(data.ip);
  };

  React.useEffect(() => {
    fetchIpAddress();
  }, []);

  const handleChange = async(e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async(e) => {
    e.preventDefault();

    const { name, email, phone, subject, message, captcha } = formData;

    if (!validate()) {
      return;
    }
    
    const data = new FormData();
    data.append('name', name);
    data.append('email', email);
    data.append('phone', phone);
    data.append('subject', subject);
    data.append('message', message);
    data.append('ip_address', ipAddress);
    data.append('captcha', captcha);

    try {
      const response = await fetch('../c0ntact.php', {
        method: 'POST',
        body: data,
      });

      const result = await response.text();
      alert(result);
    } catch (error) {
      console.error('Error:', error);
      alert('There was an error submitting the form. Please try again.');
    }
  };

  const validate = () => {
    const { email, phone, captcha } = formData;

    if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/.test(email)) {
      alert("You have entered an invalid email address!");
      return false;
    }

    const stripped = phone.replace(/[()\- ]/g, '');
    if (isNaN(parseInt(stripped))) {
      alert("The phone number contains illegal characters.");
      return false;
    } else if (stripped.length !== 9) {
      alert("The phone number should have 9 digits.");
      return false;
    }
    if (isNaN(parseInt(captcha))) {
      alert("The captcha contains illegal characters.");
      return false;
    } else if (captcha.length !== 5) {
      alert("The captcha should have 5 digits.");
      return false;
    }

    return true;
  };

  return (
    <section className="contact-page-section" id="contact">
      <div className="container my-auto">
        <div className="row">
          <div className="col-lg-12 mx-auto">
	  <b>Address:</b> Adelaide SA 5113, Australia
          <br />
	  <b>Hours Open:</b> 9 AM to 6 PM
          <br />
          <b>Phone:</b> +61 4 7029 0341
          <br />
	  <a href="mailto:hello@carbonconsultancy.net">Email us</a>
            <h2>Leave A Message</h2>
            <br />
            <div className="contact-form">
              <form id="contact-form" onSubmit={handleSubmit}>
                <div className="row clearfix">
                  <div className="form-group col-12">
                    <input
	              autocomplete="on"
                      type="text"
                      name="name"
                      maxLength="40"
                      value={formData.name}
                      placeholder="Name"
                      required
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-group col-12">
                    <input
	              autocomplete="on"
                      type="email"
                      id="email"
                      name="email"
                      maxLength="40"
                      value={formData.email}
                      placeholder="Email"
                      required
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-group col-12">
                    <input
	              autocomplete="on"
                      type="number"
                      id="phone"
                      name="phone"
                      value={formData.phone}
                      placeholder="Phone"
                      required
                      maxLength="10"
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-group col-12">
                    <input
                      type="text"
                      name="subject"
                      maxLength="60"
                      placeholder="Subject"
                      value={formData.subject}
                      required
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-group col-12">
                    <textarea
                      name="message"
                      maxLength="100"
                      placeholder="Comment..."
                      value={formData.message}
                      onChange={handleChange}
                    />
                  </div>
	          <div class="form-group col-12">
                  <strong>Enter Captcha:</strong> <img src="../captcha.php" width="120" height="30" border="1" alt="CAPTCHA" />
                  <input type="number" 
	          maxLength="5" 
	          id="captcha" 
	          name="captcha" 
	          value={formData.captcha} 
	          required 
	          onChange={handleChange}
	          />
                  </div>

                  <div className="form-group col-12">
                    <button type="submit" name="submit" className="theme-btn btn-style-one">
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
