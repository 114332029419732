import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import './Platform.css';
import logo from '../logo.jpg'; // Adjust the path as needed
import { useAuth } from '../context/AuthContext';

function Platform() {
    const { user, login } = useAuth();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const checkSession = async () => {
            const response = await fetch('../check_session.php');
            const result = await response.json();

            if (!result.loggedIn) {
                navigate('../app/signin');
            } else {
                login(result.user); // Assuming `result.user` contains user info if logged in
                setLoading(false);
            }
        };

        if (!user) {
            checkSession();
        } else {
            setLoading(false);
        }
    }, [user, navigate, login]);

    if (loading) {
        return <p>Loading...</p>;
    }
  return (
    <div> <h1> Platform Page </h1>
    <nav>
    <ul>
      <li className="logo-container"><img src={logo} alt="Logo" className="logo" /></li>
      <li><Link to="/">Home</Link></li>
	  <li><Link to="/platform/assets">Assets</Link></li>
      <li><Link to="/platform/inspection">Inspection</Link></li>
      <li><Link to="/platform/sensors">Sensors</Link></li>
      <li><Link to="/platform/issues">Issues</Link></li>
      <li><Link to="/platform/integrations">Integrations</Link></li>
      <li><Link to="/platform/monitoring">Monitoring</Link></li>
      <li><Link to="/platform/aitools">AI Tools</Link></li>
	  </ul>
	  </nav>
      </div>

  );
}

export default Platform;
